<style lang="scss">
@import "./ProgressRing";
</style>

<template>
  <div class="progress_ring__container">
    <svg class="progress_ring" :height="size" :width="size">
      <circle
        class="progress_ring__outercircle"
        :stroke-width="thickness"
        :cx="outerRadius"
        :cy="outerRadius"
        :r="radius"
      />
      <circle
        class="progress_ring__circle"
        :stroke-width="thickness"
        :cx="outerRadius"
        :cy="outerRadius"
        :style="style"
        :r="radius"
      />
    </svg>
    <span class="progress_ring__percent">
      <span class="indicator_value">
        <Tween v-if="tween" :value="val" />
        <span v-else>{{ val }}</span>
      </span>
      <span class="indicator_unit">%</span>
    </span>
  </div>
</template>

<script>
import Tween from "@/views/common/Tween.vue";
import { cap } from '@/utils/display';

export default {
  props: {
    size: {
      default: 80,
    },
    thickness: {
      default: 8,
    },
    percent: {
      default: 0,
    },
    tween: {
      default: false,
    }
  },
  components: {
    Tween,
  },
  data() {
    return {};
  },
  computed: {
    outerRadius() {
      return this.size / 2;
    },
    radius() {
      return this.outerRadius - this.thickness / 2;
    },
    circumference() {
      return this.radius * 2 * Math.PI;
    },
    style() {
      return {
        strokeDashoffset: this.circumference - (cap(this.percent, { min: 0, max: 100 }) / 100) * this.circumference,
        strokeDasharray: this.circumference + ' ' + this.circumference,
      };
    },
    val() {
      return Math.round(this.percent);
    },
  },
};
</script>
