<template>
  <div class="indicator_block">
    <span :class="['indicator_value', size]">
      <Tween v-if="tween" :value="val" />
      <span v-else>{{ val }}</span>
    </span>
    <span v-if="unit || money" class="indicator_unit">{{
      unit + (money ? "&euro;" : "")
    }}</span>
    <div v-if="foot" class="indicator_foot">{{ foot }}</div>
  </div>
</template>

<script>
import { nFormatArray, numberFormat } from "@/utils/display";
import Tween from "@/views/common/Tween.vue";

export default {
  props: ["value", "foot", "money", "tween", "size", "long"],
  components: {
    Tween,
  },
  computed: {
    arrayValue() {
      return nFormatArray(this.value);
    },
    val() {
      if (!this.value) return 0;
      if (this.long) return numberFormat(this.value);
      return parseFloat(this.arrayValue[0]);
    },
    unit() {
      if (this.long) return '';
      return this.arrayValue[1];
    },
  },
};
</script>
